:root {
  --bullet-list-color: currentColor;
  --bullet-list-spacing: 0.4rem;
}

.bullet-list {
  --bullet-list-start-padding: 1rem;

  color: var(--bullet-list-color);
  line-height: $line-height-body;
  padding-left: 2.4rem;

  & & {
    margin-top: var(--bullet-list-spacing);
  }
}

.bullet-list--unordered {
  --bullet-list-start-padding: 0.5rem;

  list-style-type: disc;
}

.bullet-list__item {
  padding-left: var(--bullet-list-start-padding);

  & + & {
    margin-top: var(--bullet-list-spacing);
  }
}
