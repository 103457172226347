.preview {
  min-height: 100vh;
  padding: 2rem;
}

.preview--paddingless {
  padding: 0;
}

.preview--grey-100 {
  background-color: $color-grey-100;
}
