.copyright {
  color: $color-grey-60;
  display: inline-flex;
  font-size: 1.2rem;
  justify-content: flex-end;
  line-height: $line-height-body;
  text-align: left;
}

.copyright__icon {
  $margin-top: (1.2rem * $line-height-body - 1.4rem) * 0.5;

  flex-shrink: 0;
  font-size: 1.4rem;
  line-height: 0;
  margin-right: 0.7rem;
  margin-top: $margin-top;
}

.copyright__text {
  --link-color: #{$color-grey-60};
  --link-hover-color: #{$color-grey-100};
  --link-underline-height: 1px;
  --link-font-weight: #{$font-weight-regular};
}
