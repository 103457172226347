:root {
  // stylelint-disable-next-line length-zero-no-unit
  --root-offset: 0px;
}

html {
  color: $root-color;
  font-family: $font-stack-root;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: $font-weight-regular;
  height: 100%;
  line-height: 1;
  margin-top: var(--root-offset);
  min-height: 100%;
  min-width: $root-min-width;
  scroll-behavior: smooth;
}
