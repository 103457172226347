.hero {
  @include use-responsive-sizing(--hero-section-gap, (
    xs: 3rem,
    m: 4rem,
    l: 5rem,
  ));

  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 3rem,
    m: 4rem,
    l: 5rem,
    xl: 5rem,
  ));

  position: relative;
}

.hero__inner {
  @include use-container();
}

.hero__head {
  @include use-responsive-sizing(column-gap, $gaps);
  @include use-responsive-sizing(row-gap, map-multiply($gaps, 1.5));

  display: grid;
  grid-template-areas: 'headline' 'image' 'content';

  &:not(:last-child) {
    margin-bottom: calc(var(--hero-section-gap) * 1.5);
  }

  @include mq($from: s) {
    grid-template-areas: 'headline headline' 'content image';
    grid-template-columns: 1fr 1fr;
  }
}

.hero--home .hero__head {
  @include mq($from: m) {
    grid-template-areas: 'headline image' 'content image';
    grid-template-rows: auto minmax(0, 1fr);
  }
}

.hero__headline {
  grid-area: headline;

  @include mq($from: m) {
    padding-bottom: 3rem;
  }
}

.hero__image {
  align-content: center;
  display: flex;
  flex-direction: column;
  grid-area: image;
  justify-content: center;
}

.hero__content {
  @include use-responsive-sizing(font-size, (
    xs: 1.8rem,
    m: 2rem,
    l: 2.4rem,
  ));

  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 1em;
  grid-area: content;
  justify-content: flex-start;
}

.hero__call-to-action {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;
  gap: 1.5rem;
  justify-content: center;
  margin-top: var(--hero-section-gap);
  text-align: center;

  @include mq($from: m) {
    font-size: 2.4rem;
  }
}

.hero__call-to-action-line {
  flex-basis: 100%;

  @include mq($from: m) {
    flex-basis: auto;
  }
}

.hero__call-to-action-text {
  flex-basis: 100%;
  font-size: 1.6rem;
  margin-top: 0.5rem;
}

.hero__logo-bar {
  margin-top: var(--hero-section-gap);
}
