.section {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 3rem,
    m: 4rem,
    l: 5rem,
    xl: 5rem,
  ));

  position: relative;
}

.section--odd {
  background-color: $color-grey-10;
}

.section__inner {
  @include use-container();
}

.section__intro {
  @include use-responsive-sizing(width, (
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  @include use-responsive-sizing(font-size, (
    xs: 1.8rem,
    l: 2rem,
  ));

  margin-top: 2rem;
}

.section__text {
  @include use-responsive-sizing(width, (
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  @include use-responsive-sizing(font-size, (
    xs: 1.6rem,
    l: 1.8rem,
  ));

  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section__content:not(:first-child) {
  margin-top: 5rem;
}

.section__content:not(:last-child) {
  margin-bottom: 5rem;
}

.section__text:not(:first-child),
.section__call-to-action:not(:first-child) {
  margin-top: 2rem;
}

.section__call-to-action:not(:last-child) {
  margin-bottom: 2.5rem;
}

.section__call-to-action {
  text-align: center;
}
