:root {
  --headline-color: currentColor;
  --headline-link-color: var(--link-color);
  --headline-link-hover-color: var(--link-hover-color);
}

.headline {
  color: var(--headline-color);
  font-weight: $font-weight-bold;
  line-height: $line-height-headline;
}

.headline--1 {
  @include use-responsive-sizing(font-size, responsive-map(xs 4rem, xl 6.4rem));
}

.headline--2 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.4rem, xl 4rem));
}

.headline--3 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2rem, xl 2.5rem));
}

.headline--4 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.2rem, xl 2.4rem));
}

.headline--5 {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.8rem, xl 2.2rem));
}

.headline__thin {
  font-weight: $font-weight-regular;
}

.headline__link {
  color: var(--headline-link-color);
  display: block;

  &:hover,
  &:focus {
    color: var(--headline-link-hover-color);
  }
}

.headline__kicker {
  color: var(--headline-kicker-color, currentColor);
  display: block;
  font-size: var(--headline-kicker-font-size, 1.6rem);
  font-weight: var(--headline-kicker-font-weight, bold);
  line-height: $line-height-body;
  margin-bottom: var(--headline-kicker-margin, 0.8rem);
  text-transform: none;
}

.headline__date {
  color: var(--headline-date-color, currentColor);
  display: block;
  font-size: var(--headline-date-font-size, 1.6rem);
  font-weight: var(--headline-date-font-weight, normal);
  line-height: $line-height-body;
  margin-bottom: var(--headline-date-margin, 0.8rem);
  text-transform: none;
}
