.slider__inner {
  overflow: hidden;
  position: relative;
}

.slider__slides {
  @include use-break-out();

  align-items: stretch;
  display: flex;
  position: relative;
  transform: translateX(0);
  transition-duration: $duration-long;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  z-index: 1;
}

.slider__slide {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.slider__navigation {
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: flex-end;
  padding-bottom: 1rem;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.slider__navigation-inner {
  @include use-container();

  align-items: center;
  display: flex;
  justify-content: space-between;

  @include mq($from: m) {
    align-items: flex-end;
  }
}

.slider__navigation-dots {
  display: flex;
  gap: 0.8rem;

  @include mq($from: m) {
    padding-bottom: 1rem;
  }
}

.slider__navigation-dot {
  background-color: $color-grey-100;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 1rem;
  opacity: 0.3;
  pointer-events: all;
  transition-property: opacity;
  width: 1rem;

  &:hover,
  &:not(.has-invisible-focus):focus {
    opacity: 0.7;
  }

  &[data-active='true'] {
    opacity: 1;
  }
}

.slider__navigation-button {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.8rem, xl 4rem));

  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 2em;
  justify-content: center;
  line-height: 0;
  padding: 0.5em;
  pointer-events: all;
  transition-property: background-color;
  width: 2em;

  &:hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-white;
  }
}

.slider__navigation-button--prev {
  margin-left: -0.5em;
}

.slider__navigation-button--next {
  margin-right: -0.5em;
}
