:root {
  --logo-height: auto;
  --logo-width: 100%;
}

.logo {
  display: block;
  line-height: 0;
  position: relative;
}

.logo__logo {
  height: var(--logo-height);
  width: var(--logo-width);

  .logo--monochrome & {
    filter: brightness(0%) invert(100%);
  }
}
