.logo-bar {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq($from: m) {
    flex-wrap: nowrap;
  }
}

.logo-bar__label {
  color: $color-grey-60;
  font-size: 2rem;
  line-height: $line-height-body;
  padding-bottom: 1.5rem;
  text-align: center;

  @include mq($from: s) {
    flex-basis: 100%;
  }

  @include mq($from: m) {
    max-width: 20rem;
    padding-bottom: 0;
    padding-right: 2.5rem;
    text-align: right;
  }

  @include mq($from: l) {
    max-width: 30rem;
  }
}

.logo-bar__logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  @include mq($from: m) {
    border-left: 1px solid $color-grey-40;
    padding: 0 2.5rem;
  }
}
