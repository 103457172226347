@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/lato-v20-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/lato-v20-latin-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/lato-v20-latin-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/lato-v20-latin-700italic.woff2') format('woff2');
}
