@keyframes header-fade-in {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    opacity: 1;
  }
}

.header {
  --header-navigation-link-background-color: transparent;
  --header-navigation-link-color: #{$brand-color-dark};
  --header-navigation-link-hover-background-color: #{$color-blue-20};
  --header-navigation-link-hover-color: #{$brand-color-dark};
  --header-navigation-link-active-background-color: #{$brand-color-dark};
  --header-navigation-link-active-color: #{$color-white};
  --header-navigation-link-before-background: var(--header-navigation-link-background-color);

  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 2rem,
    m: 3rem,
    l: 4rem,
  ));

  position: relative;
}

.header__inner {
  @include use-container();

  align-items: flex-end;
  display: flex;
}

.header__logo {
  @include use-responsive-sizing(width, (
    xs: 16rem,
    m: 18rem,
    l: 20rem,
    xl: 22.5rem,
  ));

  margin-right: auto;
}

.header__navigation-button {
  margin-bottom: -0.8rem;

  @include mq($from: m) {
    display: none;
  }
}

.header__navigation {
  @include use-responsive-sizing(--header-navigation-padding, $inner-container-padding, $end-breakpoint: l, $scoped: true);

  animation: header-fade-in 0.2s;
  background-color: $brand-color-dark;
  bottom: 0;
  display: none;
  left: 0;
  padding: 3rem var(--header-navigation-padding);
  position: fixed;
  right: 0;
  top: calc(9.9rem + var(--root-offset));
  z-index: z-index('menu');

  &::before {
    border-bottom: 1rem solid $brand-color-dark;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    content: '';
    height: 0;
    position: absolute;
    right: calc(var(--header-navigation-padding) + 4.4rem + 0.8rem + 1.2rem);
    top: -1rem;
    width: 0;
  }

  @include mq($until: m) {
    --header-navigation-link-background-color: transparent;
    --header-navigation-link-color: #{$color-white};
    --header-navigation-link-hover-background-color: #{$color-blue-20};
    --header-navigation-link-hover-color: #{$brand-color-dark};
    --header-navigation-link-active-background-color: #{$color-white};
    --header-navigation-link-active-color: #{$brand-color-dark};
    --header-navigation-link-before-display: none;
  }

  @include mq($from: m) {
    animation: none;
    background-color: transparent;
    display: flex;
    padding: 0;
    position: static;

    &::before {
      display: none;
    }
  }
}

.header__navigation--open {
  display: flex;
}

.header__navigation-close {
  position: absolute;
  right: var(--header-navigation-padding);
  top: 2rem;

  @include mq($from: m) {
    display: none;
  }
}

.header__navigation-items {
  --focus-outline-offset: 0;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: $line-height-body;
  margin-left: -1rem;
  margin-right: 6rem;
  margin-top: -1rem;
  overflow-x: none;
  overflow-y: auto;

  @include mq($from: m) {
    flex-direction: row;
    margin-bottom: -0.8rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    overflow: visible;
  }
}

.header__navigation-item {
  @include use-responsive-sizing(font-size, (
    xs: 2rem,
    l: 2.2rem,
  ));

  display: block;
  font-weight: bold;
  line-height: 2rem;
  position: relative;

  & + & {
    margin-top: 0.4rem;

    @include mq($from: m) {
      margin-left: 0.2rem;
      margin-top: 0;
    }
  }
}

.header__navigation-link {
  --focus-outline-offset: -4px;

  background-color: var(--header-navigation-link-background-color);
  color: var(--header-navigation-link-color);
  cursor: pointer;
  display: block;
  padding: 1rem;
  position: relative;
  text-align: left;
  width: 100%;

  &:hover,
  &:not(.has-invisible-focus):focus {
    --header-navigation-link-before-background: var(--header-navigation-link-hover-background-color);

    background-color: var(--header-navigation-link-hover-background-color);
    color: var(--header-navigation-link-hover-color);
  }

  &[aria-expanded='true'],
  &.header__navigation-link--active,
  &.header__navigation-link--active:focus {
    --focus-outline-color: #{$color-white};
    --header-navigation-link-before-background: var(--header-navigation-link-active-background-color);

    background-color: var(--header-navigation-link-active-background-color);
    color: var(--header-navigation-link-active-color);
  }

  &::before {
    background-color: var(--header-navigation-link-before-background);
    bottom: 100%;
    content: '';
    display: var(--header-navigation-link-before-display, block);
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -100vh;
  }
}

.header__navigation-link-icon {
  font-size: 2.4rem;
}

.header__navigation-menu {
  padding-top: 0.5rem;

  @include mq($from: m) {
    background-color: $color-blue-20;
    box-shadow: 0 0.5rem 0 0 rgba($color-blue-20, 0.25);
    inset: 100% 50% auto 50%;
    margin-top: 1.6rem;
    padding-bottom: 0.5rem;
    position: absolute;
    transform: translateX(-50%);
    width: 30rem;

    &::before {
      border-bottom: 1rem solid $color-blue-20;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      content: '';
      height: 0;
      left: 50%;
      position: absolute;
      top: -1rem;
      transform: translateX(-50%);
      width: 0;
    }
  }
}

.header__navigation-menu-item {
  display: block;
  font-size: 1.8rem;
  line-height: $line-height-body;
  text-align: left;
}

.header__navigation-menu-link {
  color: $color-white;
  display: block;
  padding: 0.8rem 2rem;

  &:hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-blue-20;
    color: $brand-color-dark;
  }

  @include mq($from: m) {
    color: $brand-color-dark;
    padding: 1.2rem 1.5rem;

    &:hover,
    &:not(.has-invisible-focus):focus {
      background-color: $color-blue-40;
      color: $brand-color-dark;
    }
  }
}

.header__search {
  flex-shrink: 0;
  margin-bottom: -0.8rem;
  margin-left: 0.8rem;
  position: relative;
  text-align: right;

  @include mq($from: m) {
    margin-left: 0;
  }
}

.header__search-form {
  align-items: center;
  animation: header-fade-in 0.3s;
  background-color: $color-blue-20;
  box-shadow: 0 0.5rem 0 0 rgba($color-blue-20, 0.25);
  inset: 100% 0 auto auto;
  margin-top: 1.6rem;
  max-width: 40rem;
  padding: 2rem;
  position: absolute;
  width: 89vw;
  z-index: z-index('search');

  &:not([hidden]) {
    display: block;
  }

  &::before {
    border-bottom: 1rem solid $color-blue-20;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    content: '';
    height: 0;
    position: absolute;
    right: 1rem;
    top: -1rem;
    width: 0;
  }
}

.header__search-form-button {
  margin-top: 1rem;
}
