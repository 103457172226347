:root {
  --quote-color: #{$brand-color-dark};
  --quote-icon-color: #{$brand-color-dark};
}

.quote {
  align-items: flex-start;
  color: var(--quote-color);
  display: flex;
  flex-direction: row;
  line-height: $line-height-body;
  text-align: left;
}

.quote__icon {
  @include use-responsive-sizing(font-size, responsive-map(xs 3.2rem, xl 5.6rem));

  color: var(--quote-icon-color);
  line-height: 0;
  margin-right: 0.5em;
  margin-top: 0.3rem;
}

.quote__quote {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.4rem, xl 4rem));
}

.quote__caption {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.4rem, xl 2.2rem));

  margin-top: 1rem;

  &::before {
    content: '—';
    margin-right: 0.5em;
  }
}
