.figure {
  display: block;
}

.figure__figure {
  position: relative;
}

.figure__caption {
  --link-color: #{$color-grey-100};

  color: $color-grey-80;
  font-size: 1.6rem;
  line-height: $line-height-body;
  margin-top: 1.2rem;
  text-align: left;
}

.figure__copyright {
  margin-top: 1rem;
}
