:root {
  --slide-text-position: center;
  --slide-item-position: center;
  --slide-item-content-margin-left: auto;
  --slide-item-content-margin-right: auto;
}

.slide {
  @include use-responsive-sizing(min-height, responsive-map(xs 45rem, xl 52rem));

  background-color: $color-grey-80;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.slide__image {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: -1;

  &::after {
    background-image: linear-gradient(to bottom, transparent, ease-in-out, rgba($color-white, 0.8));
    content: '';
    inset: 0;
    position: absolute;
  }
}

.slide__inner {
  @include use-container();
  @include use-responsive-sizing(text-align, (xs: center, m: var(--slide-text-position)));

  padding-bottom: 6rem;
  padding-top: 6rem;
}

.slide__content {
  @include use-responsive-sizing(width, (
    xs: column-width(xs, 12),
    s: column-width(s, 12),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  margin-left: var(--slide-item-content-margin-left);
  margin-right: var(--slide-item-content-margin-right);
}

.slide__description {
  font-size: 1.6rem;
  line-height: $line-height-body;
  margin-top: 2rem;

  @include mq($from: s) {
    font-size: 1.8rem;
  }
}

.slide__buttons {
  @include use-responsive-sizing(justify-content, (xs: center, m: var(--slide-item-position)));

  display: flex;
  flex-wrap: wrap;
  gap: 2rem 3rem;
  margin-top: 3rem;
}
