.two-column-text {
  @include use-responsive-sizing(gap, $gaps);

  display: flex;
  flex-wrap: wrap;
}

.two-column-text__text {
  @include use-responsive-sizing(font-size, (
    xs: 1.8rem,
    m: 1.9rem,
    l: 2rem,
  ));

  @include use-responsive-sizing(width, (
    m: column-width(m, 6),
    l: column-width(l, 6),
    xl: column-width(xl, 6),
  ));

  flex-grow: 1;
  flex-shrink: 0;
  line-height: $line-height-body;

  > * + * {
    margin-top: 1em;
  }

  > * + .headline {
    margin-top: 1.5em;
  }
}
