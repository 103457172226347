@use 'sass:math';
@use 'sass:color';

// Root
$root-color: $color-grey-100;
$root-font-size-px: 10px;
$root-min-width: 320px;

// Body
$body-background-color: $color-white;
$body-font-size: 1.6rem;

// Link
$link-color: $brand-color-dark;
$link-hover-color: color.mix($color-black, $link-color, 20%);

// Skip link
$skip-link-background-color: $color-grey-100;
$skip-link-color: $color-white;

// Font families
$font-stack-body: 'Lato', Helvetica, Arial, sans-serif;
$font-stack-root: $font-stack-body;

// Font weights
$font-weight-regular: 400;
$font-weight-bold: 700;

// Line height
$line-height-headline: 1.125;
$line-height-body: 1.25;

// Durations
$duration-default: 0.2s;
$duration-long: 0.4s;

// Z-Index
$z-index-list: 'skip-link', 'menu', 'search', 'dropdown', 'header', 'default';

// Focus
$focus-outline-color: $brand-color-dark;

// Columns
$columns: 12;

// Gap
$gaps: (
  xs: 2.5rem,
  s: 2.5rem,
  m: 2.5rem,
  l: 2.5rem,
  xl: 2.5rem,
);

// stylelint-disable length-zero-no-unit
$mq-breakpoints: (
  xs: 0px,
  s: 600px,
  m: 768px,
  l: 1024px,
  xl: 1440px,
);
// stylelint-enable

// Inner container
$inner-container-padding: (
  xs: 5.5vw,
  s: 5.5vw,
  m: 5vw,
  l: 5vw,
  xl: 5vw,
);

$inner-container-max-width: 111rem;
$inner-container-max-padding: math.div(map-get($inner-container-padding, xl), 100vw) * $inner-container-max-width;

// Exports
:export {
  l: map-get($mq-breakpoints, l);
  m: map-get($mq-breakpoints, m);
  s: map-get($mq-breakpoints, s);
  xl: map-get($mq-breakpoints, xl);
  xs: map-get($mq-breakpoints, xs);
}
