.image {
  display: block;
  line-height: 0;
  position: relative;
}

.image--cover {
  flex-grow: 1;
}

.image__img {
  font-size: 1.4rem;
  height: auto;
  transition-duration: $duration-long;
  transition-property: opacity;
  width: 100%;

  .js & {
    opacity: 0;
  }

  .js &.image__img--loaded {
    opacity: 1;
  }

  .image--cover & {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}
