@keyframes show-dropdown-children {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown__items {
  animation: show-dropdown-children $duration-default;
  background-color: var(--button-background-color);
  box-shadow: 0 0.5rem 0 0 rgba(0, 0, 0, 0.15);
  color: $color-white;
  left: 0;
  position: absolute;
  top: 100%;
  width: 30rem;
  z-index: z-index('dropdown');
}

.dropdown__item {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  display: block;
  font-size: 1.8rem;
  line-height: $line-height-body;
  position: relative;
  text-align: left;

  & + & {
    box-shadow: inset 0 1px 0 0 var(--button-active-background-color);
  }
}

.dropdown__link {
  display: block;
  padding: 1.5rem 2rem;

  &:hover,
  &:focus {
    background-color: var(--button-active-background-color);
  }
}
