.list__inner {
  @include use-responsive-sizing(--list-padding, (
    xs: 3rem,
    m: 4rem,
    l: 5rem,
    xl: 5rem,
  ));

  @include use-container();

  padding-bottom: var(--list-padding);

  &:first-child {
    padding-top: var(--list-padding);
  }
}

.list__search {
  @include use-break-out();

  background-color: $color-blue-20;
  margin-bottom: var(--list-padding);
  padding-bottom: var(--list-padding);
  padding-top: var(--list-padding);
}

.list__search-form {
  @include use-container();

  text-align: right;
}

.list__search-form-button {
  margin-top: 1rem;
}

.list__pagination {
  margin-top: var(--list-padding);
}
