// Base colors
$color-black: #000;
$color-white: #fff;

// Blue
$color-blue-100: #899ab9;
$color-blue-80: #a1aec7;
$color-blue-60: #b8c2d5;
$color-blue-40: #d0d7e3;
$color-blue-20: #e7ebf1;

// Grey
$color-grey-100: #3c4448;
$color-grey-80: #636a71;
$color-grey-75: #6c737a;
$color-grey-60: #898f94;
$color-grey-45: #a7abaf;
$color-grey-40: #b1b5b8;
$color-grey-30: #c4c7c9;
$color-grey-20: #d8dadc;
$color-grey-15: #e1e3e4;
$color-grey-10: #ebeced;
$color-grey-5: #f5f5f6;

// Dark violet
$color-dark-violet-100: #682c8b;
$color-dark-violet-80: #8656a2;
$color-dark-violet-60: #a480b9;
$color-dark-violet-40: #c2aad0;
$color-dark-violet-20: #e0d4e7;

// Violet
$color-violet-100: #af2d7b;
$color-violet-80: #bf5796;
$color-violet-60: #cf81b0;
$color-violet-40: #dfabca;
$color-violet-20: #efd5e4;

// Red
$color-red-100: #ae0a0d;
$color-red-80: #de3333;
$color-red-60: #e76666;
$color-red-40: #ef9999;
$color-red-20: #f7cccc;
$color-red-10: #fdf2f2;

// Orange
$color-orange-100: #f26e00;
$color-orange-80: #f58b33;
$color-orange-60: #f7a866;
$color-orange-40: #fac599;
$color-orange-20: #fce2cc;

// Yellow
$color-yellow-100: #ffbd33;
$color-yellow-80: #ffca5c;
$color-yellow-60: #ffd785;
$color-yellow-40: #ffe4ad;
$color-yellow-20: #fff2d6;

// Green
$color-green-100: #6ead10;
$color-green-80: #8bbd40;
$color-green-60: #a8ce70;
$color-green-40: #c5dea0;
$color-green-20: #e2efcf;

// Dark Green
$color-dark-green-100: #008512;
$color-dark-green-80: #339d41;
$color-dark-green-60: #66b570;
$color-dark-green-40: #99cea0;
$color-dark-green-20: #cce6cf;

// Petrol
$color-petrol-100: #129c94;
$color-petrol-80: #41afaa;
$color-petrol-60: #70c3bf;
$color-petrol-40: #a0d7d4;
$color-petrol-20: #cfebe9;

// Brown
$color-brown-100: #a85d45;
$color-brown-80: #b97d6a;
$color-brown-60: #ca9e8f;
$color-brown-40: #dcbeb4;
$color-brown-20: #edded9;
$color-brown-15: #f9f2ec;
$color-brown-10: #fef8f2;

// Aliases
$brand-color-dark: #134094;
$brand-color-lighter: $color-blue-100;
$content-color: $color-blue-100;
$base-grey: $color-grey-100;
$dark-grey-color: $color-grey-100;
$light-grey-color: $color-grey-75;
$activity-color: $color-yellow-100;
