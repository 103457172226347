.nf-error-msg {
  font-size: 1.6rem;
  margin-top: 1.5rem;
}

.nf-form-title {
  display: block;
  font-weight: $font-weight-bold;
  margin-bottom: 2rem;
}

.nf-field-label > label {
  display: block;
  font-size: 1.6rem;
  font-weight: normal !important;
  line-height: $line-height-body;
  margin-bottom: 1rem;
}

.nf-field .horizontal-rule {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.nf-field .field-wrap {
  flex-wrap: wrap;
}

.nf-field-description {
  flex-shrink: 0;
  font-size: 1.6rem;
  line-height: $line-height-body;
  margin-top: 1.2rem;
  width: 100%;
}

.nf-form-fields-required {
  color: $color-grey-80;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
