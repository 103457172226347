@use 'sass:math';

/// use-fluid-sizing
/// Generate linear interpolated size values through multiple breakpoints
/// @param $properties - A string or list of CSS property name
/// @param $map - A Sass map of viewport unit and size value pairs
/// @requires function linear-interpolation
/// @requires function map-sort
/// @example
///   @include use-fluid-sizing('padding', (576px: 22px, 768px: 24px, 992px: 34px));
@mixin use-fluid-sizing($properties, $map) {
  // Convert into a list
  @if type-of($properties) != list {
    $properties: ($properties);
  }

  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Error if the number of breakpoints is < 2
  @if ($length < 2) {
    @error 'use-fluid-sizing() $map requires at least values';
  }

  // Sort the map by viewport width (key)
  // $map: map-sort($map);
  $keys: map-keys($map);

  min-height: 0vw; // stylelint-disable-line length-zero-no-unit

  // Minimum size
  @each $property in $properties {
    #{$property}: map-get($map, nth($keys, 1));
  }

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    $min-width: nth($keys, $i);

    @if type-of($min-width) == 'number' and unit($min-width) == rem {
      $min-width: rem2px($min-width);
    }

    @include mq($from: $min-width) {
      $value1: map-get($map, nth($keys, $i));
      $value2: map-get($map, nth($keys, ($i + 1)));

      // If values are not equal, perform linear interpolation
      @if ($value1 != $value2) {
        @each $property in $properties {
          $key1: nth($keys, $i);
          $key2: nth($keys, ($i + 1));

          @if type-of($key1) == 'string' {
            $key1: harmonize-value(map-get($mq-breakpoints, $key1), $value1);
          }

          @if type-of($key2) == 'string' {
            $key2: harmonize-value(map-get($mq-breakpoints, $key2), $value2);
          }

          #{$property}: linear-interpolation(($key1: $value1, $key2: $value2));
        }
      } @else {
        @each $property in $properties {
          #{$property}: $value1;
        }
      }
    }
  }

  // Maxmimum size
  // stylelint-disable-next-line order/order
  $max-width: nth($keys, $length);

  @if type-of($max-width) == 'number' and unit($max-width) == rem {
    $max-width: rem2px($max-width);
  }

  @include mq($from: $max-width) {
    @each $property in $properties {
      #{$property}: map-get($map, nth($keys, $length));
    }
  }
}

/// use-responsive-sizing
/// Generate values through multiple breakpoints
/// @param $properties - A string or list of CSS property name
/// @param $breakpoint-map - A Sass map of breakpoints and the value for the breakpoint
/// @param $start-breakpoint - Starting breakpoint
/// @param $end-breakpoint - Ending breakpoint
/// @param $scoped - Is the last breakpoint open end?
/// @requires function list-slice
/// @requires function px2rem
/// @example
///   @include use-responsive-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
@mixin use-responsive-sizing(
  $properties,
  $breakpoint-map,
  $start-breakpoint: nth(map-keys($breakpoint-map), 1),
  $end-breakpoint: nth(map-keys($breakpoint-map), length(map-keys($breakpoint-map))),
  $scoped: false,
) {
  $all-breakpoints: map-keys($breakpoint-map);
  $start-index: index($all-breakpoints, $start-breakpoint);
  $end-index: index($all-breakpoints, $end-breakpoint);
  $breakpoints: list-slice($all-breakpoints, $start-index, $end-index);

  @if type-of($properties) != list {
    $properties: ($properties);
  }

  @each $breakpoint in $breakpoints {
    $index: index($all-breakpoints, $breakpoint);
    $next-breakpoint: false;

    @if $scoped and length($all-breakpoints) != $index {
      $next-breakpoint: nth($all-breakpoints, $index + 1);
    }

    @include mq($from: $breakpoint, $until: $next-breakpoint) {
      $value: map-get($breakpoint-map, $breakpoint);

      @if type-of($value) == number and unit($value) == px {
        $value: px2rem($value);
      }

      @each $property in $properties {
        #{$property}: $value;
      }
    }
  }
}

/// use-container
/// Generate a container with a maximal width
/// @param $max-width - Maximal width
/// @param $padding - Padding in vw
/// @param $max-padding - Max Padding in rem
@mixin use-container(
  $max-width: $inner-container-max-width,
  $padding: $inner-container-padding,
  $max-padding: $inner-container-max-padding,
  $start-breakpoint: nth(map-keys($padding), 1),
  $end-breakpoint: nth(map-keys($padding), length(map-keys($padding))),
  $scoped: false,
) {
  @include use-responsive-sizing(padding-left padding-right, $padding, $start-breakpoint, $end-breakpoint, $scoped);

  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
  width: 100%;

  @include mq($from: rem2px($max-width)) {
    padding-left: $max-padding;
    padding-right: $max-padding;
  }
}

/// use-body-padding
/// Adds body padding
/// @param $padding - Padding in vw
/// @param $max-padding - Max Padding in rem
/// @param $max-width - Maximal width
@mixin use-body-padding(
  $padding: $inner-container-padding,
  $max-padding: $inner-container-max-padding,
  $start-breakpoint: nth(map-keys($padding), 1),
  $end-breakpoint: nth(map-keys($padding), length(map-keys($padding))),
  $scoped: false,
  $max-width: $inner-container-max-width,
) {
  @include use-responsive-sizing(padding-left padding-right, $padding, $start-breakpoint, $end-breakpoint, $scoped);

  @include mq($from: rem2px($max-width)) {
    padding-left: $max-padding;
    padding-right: $max-padding;
  }
}

/// use-break-out
/// Breaks out of a container
@mixin use-break-out() {
  margin-left: calc((100vw - 100%) / -2);
  margin-right: calc((100vw - 100%) / -2);
}

/// use-clearfix
/// Generate a clearfix
@mixin use-clearfix() {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

/// use-scrim-gradient
/// Generate a smooth gradient from a color into transparent
/// @param $start-color - Start color
/// @param $direction - Gradient direction
@mixin use-scrim-gradient($start-color: #000, $direction: 'to bottom') {
  $scrim-coordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($start-color);
  $saturation: saturation($start-color);
  $lightness: lightness($start-color);
  $stops: ();

  @each $color-stop, $alpha-value in $scrim-coordinates {
    $stop: hsla($hue, $saturation, $lightness, $alpha-value) percentage(math.div($color-stop, 100));
    $stops: append($stops, $stop, comma);
  }

  background-image: linear-gradient(unquote($direction), $stops);
}

/// use-outline
/// Generate a outline for a focus border for example
/// @param $offset - Outline offset
/// @param $color - Outline color
@mixin use-outline($offset: 5px, $color: $focus-outline-color) {
  outline: var(--focus-outline-width, 3px) solid var(--focus-outline-color, #{$color});
  outline-offset: var(--focus-outline-offset, #{$offset});
}

/// use-triangle-cut-out
/// Adds an triangle cut-out clip path
/// @param $width - Triangle width
/// @param $height - Triangle height
/// @param $x - Position from left
/// @param $y - Position from top
// stylelint-disable-next-line length-zero-no-unit
@mixin use-triangle-cut-out($width: 1rem, $height: 1rem, $x: 0px, $y: 0px) {
  $ba: math.div(360deg, 3);
  $p: ();
  $factor: 0.85714;

  @for $i from 0 to 3 {
    $ca: -90deg - 0.5 * $ba - $i * $ba;
    $p: $p, calc(#{$x} + #{$width * $factor * math.cos($ca)}) calc(#{$y} + #{$height * math.sin($ca)});
  }

  clip-path: polygon(#{$p}, 100% 0, 100% 100%, 0 100%, 0 0);
}
