.message {
  color: $color-grey-60;
  display: flex;
  font-size: 1.4rem;
  line-height: $line-height-body;
}

.message--error {
  color: $color-red-100;
  font-weight: bold;
}

.message__icon {
  $margin-top: (1.4rem * $line-height-body - 1.6rem) * 0.5;

  flex-shrink: 0;
  font-size: 1.6rem;
  line-height: 0;
  margin-right: 0.7rem;
  margin-top: $margin-top;
}

.message__text {
  --link-color: currentColor;
  --link-hover-color: #{$color-grey-100};
  --link-underline-height: 1px;
}
