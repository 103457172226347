:root {
  --form-submit-align: flex-start;
}

.form {
  font-size: 1.6rem;
  line-height: $line-height-body;
}

.form__errors {
  background-color: $color-red-10;
  border: 1px solid $color-red-40;
  color: $color-red-100;
  margin-bottom: 2rem;
  padding-bottom: 2.4rem;
  padding-left: 4.4rem;
  padding-right: 2.4rem;
  padding-top: 2.4rem;
}

.form__error {
  padding-left: 0.5rem;
}

.form__headline {
  margin-bottom: 2rem;
}

.form__field {
  margin-bottom: 2rem;
}

.form__buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: var(--form-submit-align);
  margin-top: 3rem;
}
