.embed {
  overflow: hidden;
  position: relative;
  z-index: z-index('default');
}

.embed__image {
  filter: blur(0.5rem);
  height: 100%;
  inset: -0.5rem;
  object-fit: cover;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.embed__show {
  align-items: center;
  background-blend-mode: hue;
  background-color: rgba($brand-color-dark, 0.8);
  color: $color-white;
  display: flex;
  inset: 0;
  justify-content: center;
  padding: 2rem;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.embed__show-icon {
  font-size: 2em;
  line-height: 0;
  margin-left: 1rem;
}

.embed__iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
