@use 'sass:color';

.teaser {
  position: relative;

  &:focus-within {
    @include use-outline();
  }
}

.teaser--list {
  @include use-responsive-sizing(gap, $gaps);

  @include mq($from: s) {
    display: flex;
  }
}

.teaser--news-or-event {
  --headline-color: #{$color-brown-100};
  --headline-date-color: #{$color-brown-100};
  --headline-link-color: #{$color-brown-100};
  --headline-link-hover-color: #{color.mix($color-black, $color-brown-100, 20%)};
}

.teaser__image {
  flex-basis: 25%;
  flex-shrink: 0;
  margin-bottom: 2rem;
  max-width: 30rem;

  .teaser--list & {
    @include mq($from: s) {
      margin-bottom: 0;
    }
  }
}

.teaser__content {
  flex-grow: 1;
}

.teaser__headline {
  --focus-outline-width: 0;
}

.teaser__description {
  line-height: $line-height-body;
  margin-top: 1rem;
}
