.field__legend,
.field__label {
  display: block;
  font-size: 1.6rem;
  line-height: $line-height-body;
  margin-bottom: 1rem;
}

.field__inner {
  position: relative;
}

.field__input {
  background-color: $color-white;
  border: 1px solid $color-grey-45;
  color: $color-grey-100;
  display: block;
  font-size: 1.8rem;
  height: auto;
  line-height: 1.3;
  min-width: 100%;
  padding: 1rem 1.5rem;
  transition-property: border-color, color;
  width: 100%;

  .field--error & {
    outline-color: $color-red-100;
    outline-offset: -1px;
    outline-style: solid;
    outline-width: 2px;
  }

  &:hover {
    border-color: $color-grey-80;
  }

  &:focus {
    outline-color: $brand-color-dark;
    outline-offset: -1px;
    outline-style: solid;
    outline-width: 3px;
  }

  &::placeholder {
    color: $color-grey-45;
    opacity: 1;
  }
}

.field__input--select {
  cursor: pointer;
}

.field__input--color {
  cursor: pointer;
  height: 5.4rem;
}

.field__input--number {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.field__input--hidden {
  display: none;
}

.field__input--has-icon {
  padding-right: 2.4rem;
}

.field__input-option {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.field__input-icon {
  color: $color-grey-100;
  font-size: 1.8rem;
  height: 1.8rem;
  line-height: 0;
  pointer-events: none;
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.8rem;
  z-index: 3;
}

.field__line {
  cursor: pointer;
  display: flex;
  user-select: none;

  &:focus-within {
    @include use-outline(3px);
  }

  & + & {
    margin-top: 1.2rem;
  }
}

.field__checkbox,
.field__radio {
  flex-shrink: 0;
  position: relative;
}

.field__checkbox-input,
.field__radio-input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.field__checkbox-icon {
  border: 1px solid $color-grey-45;
  color: transparent;
  cursor: pointer;
  font-size: 1.4rem;
  height: 2rem;
  line-height: 0;
  padding: 0.2rem;
  transition-property: background-color, border-color, color;
  user-select: none;
  width: 2rem;

  .field__line:hover & {
    border-color: $brand-color-dark;
  }

  .field__checkbox-input:checked ~ & {
    background-color: $brand-color-dark;
    border-color: $brand-color-dark;
    color: $color-white;
  }
}

.field__radio-icon {
  border: 1px solid $color-grey-45;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  padding: 0.5rem;
  position: relative;
  user-select: none;
  width: 2rem;

  &::after {
    background-color: $brand-color-dark;
    border-radius: 50%;
    content: '';
    height: 1.2rem;
    inset: 0.3rem;
    opacity: 0;
    position: absolute;
    transition-property: opacity;
    width: 1.2rem;
  }

  .field__line:hover &,
  .field__radio-input:checked ~ & {
    border-color: $brand-color-dark;
  }

  .field__radio-input:checked ~ &::after {
    opacity: 1;
  }
}

.field__line-label {
  align-self: center;
  flex-grow: 2;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-left: 1.2rem !important;
}

.field__error,
.field__description {
  margin-top: 1rem;
}
