:root {
  @include use-responsive-sizing(--article-padding-block, (
    xs: 3rem,
    m: 3rem,
    l: 4rem,
    xl: 5rem,
  ));
}

.article {
  @include use-responsive-sizing(padding-top, responsive-map(xs 4rem, xl 8rem));
  @include use-responsive-sizing(padding-bottom, responsive-map(xs 5rem, xl 10rem));
}

.article__inner {
  @include use-container();
}

.article__header,
.article__content {
  @include use-responsive-sizing(width, (
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  margin-left: auto;
  margin-right: auto;
}

.article__pill {
  margin-bottom: 2rem;
}

.article__image {
  margin-top: 4rem;
}

.article__content {
  @include use-clearfix();
  @include use-responsive-sizing(margin-top, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-sizing(font-size, (
    xs: 1.8rem,
    m: 1.9rem,
    l: 2rem,
  ));

  > * {
    margin-bottom: calc(var(--article-padding-block) / 2);
    margin-top: calc(var(--article-padding-block) / 2);
  }

  > * + .headline {
    margin-top: var(--article-padding-block);
  }

  > .horizontal-rule {
    margin-bottom: var(--article-padding-block);
    margin-top: var(--article-padding-block);
  }

  > :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    margin-top: 0;
  }
}

.article__aside {
  margin-bottom: var(--article-padding-block);
  margin-top: var(--article-padding-block);
  width: 100%;
}

.article__aside--left,
.article__aside--right {
  @include use-responsive-sizing(--article-figure-gap, $gaps);

  @include mq($from: s) {
    margin-top: 0.5rem;
    width: calc(50% - var(--article-figure-gap));
  }
}

.article__aside--left {
  @include mq($from: s) {
    float: left;
    margin-right: var(--article-figure-gap);
  }
}

.article__aside--right {
  @include mq($from: s) {
    float: right;
    margin-left: var(--article-figure-gap);
  }
}
