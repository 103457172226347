:root {
  --pill-font-size: 1.4rem;
  --pill-line-height: 1;
  --pill-border-color: #{$color-brown-100};
  --pill-background-color: #{$color-brown-100};
  --pill-color: #{$color-white};
  --pill-hover-border-color: var(--pill-border-color);
  --pill-hover-background-color: var(--pill-color);
  --pill-hover-color: var(--pill-background-color);
  --pill-padding-y: 0.6rem;
  --pill-padding-x: 1.4rem;
}

.pill {
  --pill-height: calc(var(--pill-font-size) * var(--pill-line-height) + var(--pill-padding-y) * 2);

  background-color: var(--pill-background-color);
  border-radius: calc(var(--pill-height) / 2);
  box-shadow: inset 0 0 0 1px var(--pill-border-color);
  color: var(--pill-color);
  display: inline-block;
  font-size: var(--pill-font-size);
  font-weight: $font-weight-bold;
  height: var(--pill-height);
  line-height: 1;
  padding: var(--pill-padding-y) var(--pill-padding-x);
  text-transform: uppercase;
  transition-property: background-color, box-shadow, color;

  &:any-link:hover,
  &:focus {
    background-color: var(--pill-hover-background-color);
    box-shadow: inset 0 0 0 1px var(--pill-hover-border-color);
    color: var(--pill-hover-color);
  }
}
