@use 'sass:color';

:root {
  --button-background-color: #{$color-dark-green-100};
  --button-focus-background-color: #{$color-dark-green-80};
  --button-active-background-color: #{color.mix($color-black, $color-dark-green-100, 20%)};
  --button-color: #{$color-white};
  --button-focus-color: #{$color-white};
  --button-active-color: #{$color-white};
  --button-line-height: 1.2;
  --button-text-align: center;

  @include use-responsive-sizing(--button-height, responsive-map(xs 4rem, xl 5rem));
  @include use-responsive-sizing(--button-font-size, responsive-map(xs 1.8rem, xl 2.4rem));
}

.button {
  --button-text-height: calc(var(--button-font-size) * var(--button-line-height));
  --button-padding-y: calc((var(--button-height) - 0.5rem - var(--button-text-height)) / 2);
  --button-padding-x: calc(var(--button-font-size) * 1.5);

  align-items: center;
  background-color: var(--button-background-color);
  box-shadow: 0 0.5rem 0 0 rgba(0, 0, 0, 0.15), inset 0 -0.5rem 0 0 rgba(0, 0, 0, 0.25);
  color: var(--button-color);
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: $font-weight-bold;
  line-height: var(--button-line-height);
  min-height: var(--button-height);
  padding: var(--button-padding-y) var(--button-padding-x) calc(var(--button-padding-y) + 0.5rem);
  transition-property: background-color, box-shadow, color, transform;
  user-select: none;

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus {
    background-color: var(--button-focus-background-color);
    box-shadow: 0 0.5rem 0 0 rgba(0, 0, 0, 0.15), inset 0 -0.5rem 0 0 rgba(0, 0, 0, 0.25);
    color: var(--button-focus-color);
  }

  &:active,
  &:active:focus,
  &:active:hover,
  &.button--active,
  &.button--active:focus {
    background-color: var(--button-active-background-color);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25), inset 0 0 0 0 rgba(0, 0, 0, 0.25);
    color: var(--button-active-color);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.button--small {
  --button-font-size: 1.6rem;
  --button-height: 3.6rem;
  --button-padding-x: var(--button-font-size);
}

.button--secondary {
  --button-background-color: #{$color-grey-100};
  --button-focus-background-color: #{$color-grey-80};
  --button-active-background-color: #{color.mix($color-black, $color-grey-100, 20%)};
}

.button--block {
  display: flex;
  width: 100%;
}

.button__text {
  flex-grow: 1;
  text-align: var(--button-text-align);
}

.button__icon {
  flex-shrink: 0;
  font-size: 1.2em;
  line-height: 0;
  margin-left: 0.3em;
}

.button__text,
.button__icon {
  transition-property: transform;

  .button:active &,
  .button--active & {
    transform: translateY(0.25rem);
  }
}
