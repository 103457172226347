/*!
 * Stud.IP
 *
 * @copyright 2022 Dennis Morhardt <info@dennismorhardt.de>
 */
@charset 'UTF-8';

/**
 * Abstracts
 */
@import 'abstracts/colors';
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

/**
 * Vendors
 */
@import 'sass-mq/mq';

/**
 * Base
 */
@import 'base/reset';
@import 'base/fonts';
@import 'base/root';
@import 'base/body';
@import 'base/globals';
@import 'base/utils';
@import 'base/focus';
@import 'base/reduced-motion';
@import 'base/grid-overlay';

/**
 * Layouts
 */
@import 'layouts/page';

/**
 * Particles
 */
@import '../components/0-particles/icon/icon';
@import '../components/0-particles/preview/preview';

/**
 * Atoms
 */
@import '../components/1-atoms/bullet-list/bullet-list';
@import '../components/1-atoms/button/button';
@import '../components/1-atoms/checklist/checklist';
@import '../components/1-atoms/copyright/copyright';
@import '../components/1-atoms/details/details';
@import '../components/1-atoms/embed/embed';
@import '../components/1-atoms/field/field';
@import '../components/1-atoms/headline/headline';
@import '../components/1-atoms/horizontal-rule/horizontal-rule';
@import '../components/1-atoms/image/image';
@import '../components/1-atoms/link/link';
@import '../components/1-atoms/logo/logo';
@import '../components/1-atoms/message/message';
@import '../components/1-atoms/paragraph/paragraph';
@import '../components/1-atoms/pill/pill';
@import '../components/1-atoms/quote/quote';
@import '../components/1-atoms/table/table';
@import '../components/1-atoms/text-button/text-button';

/**
 * Molecules
 */
@import '../components/2-molecules/alternating-grid/alternating-grid';
@import '../components/2-molecules/cta-box/cta-box';
@import '../components/2-molecules/dropdown/dropdown';
@import '../components/2-molecules/figure/figure';
@import '../components/2-molecules/form/form';
@import '../components/2-molecules/logo-bar/logo-bar';
@import '../components/2-molecules/pagination/pagination';
@import '../components/2-molecules/slide/slide';
@import '../components/2-molecules/teaser/teaser';
@import '../components/2-molecules/text-image/text-image';
@import '../components/2-molecules/two-column-text/two-column-text';

/**
 * Organisms
 */
@import '../components/3-organisms/article/article';
@import '../components/3-organisms/footer/footer';
@import '../components/3-organisms/header/header';
@import '../components/3-organisms/hero/hero';
@import '../components/3-organisms/list/list';
@import '../components/3-organisms/section/section';
@import '../components/3-organisms/slider/slider';
@import '../components/3-organisms/teaser-grid/teaser-grid';
@import '../components/3-organisms/teaser-list/teaser-list';

/**
 * External
 */
@import 'external/ninja-forms';
