.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page__content .section:last-child {
  @include use-responsive-sizing(padding-bottom, (
    xs: 4rem,
    m: 5rem,
    l: 7rem,
    xl: 8rem,
  ));
}

.page__footer {
  margin-top: auto;
}
