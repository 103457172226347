.cta-box {
  background-color: $color-brown-10;
  border: 1px solid $color-brown-20;
}

.cta-box__headline {
  background-color: $color-brown-15;
  border-bottom: 1px solid $color-brown-20;
  padding: 1rem 2rem;
}

.cta-box__content,
.cta-box__body {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.cta-box__body {
  flex-grow: 1;
}

.cta-box__content {
  padding: 2rem;

  @include mq($from: s) {
    align-items: center;
    flex-direction: row-reverse;
  }
}

.cta-box__icon {
  @include use-responsive-sizing(font-size, responsive-map(xs 5rem, xl 20rem));

  color: $color-brown-20;
  flex-shrink: 0;
  line-height: 0;
}

.cta-box__description {
  font-size: 1.6rem;
  line-height: $line-height-body;

  @include mq($from: s) {
    font-size: 1.8rem;
  }
}

.cta-box__button {
  padding-bottom: 0.5rem;
}
