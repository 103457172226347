@use 'sass:color';

.table {
  border-collapse: collapse;
  color: $color-grey-100;
  font-size: 1.6rem;
  line-height: $line-height-body;
  width: 100%;
}

.table__head-row,
.table__foot-row {
  background-color: $color-brown-15;
}

.table__row {
  &:nth-child(odd) {
    background-color: $color-brown-10;
  }

  &:nth-child(even) {
    background-color: $color-brown-15;
  }
}

.table__head-cell,
.table__foot-cell {
  font-size: 2rem;
  font-weight: $font-weight-bold;
}

.table__head-cell,
.table__cell,
.table__foot-cell {
  --link-color: #{$color-brown-100};
  --link-hover-color: #{color.mix($color-black, $color-brown-100, 20%)};

  border: 1px solid $color-brown-20;
  padding: 1rem 2rem;
  text-align: left;
  vertical-align: text-top;
}

.table__caption {
  caption-side: bottom;
  color: $color-grey-60;
  font-size: 1.4rem;
  line-height: $line-height-body;
  margin-top: 1.4rem;
  text-align: left;
}
