.teaser-grid {
  --teaser-grid-columns: 1;
}

.teaser-grid--2 {
  @include use-responsive-sizing(--teaser-grid-columns, (xs: 1, s: 2));
}

.teaser-grid--3 {
  @include use-responsive-sizing(--teaser-grid-columns, (xs: 1, s: 2, m: 3));
}

.teaser-grid__teasers {
  @include use-responsive-sizing(column-gap, $gaps);
  @include use-responsive-sizing(row-gap, map-multiply($gaps, 1.5));

  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(var(--teaser-grid-columns), 1fr);
}
