@use 'sass:color';

.checklist {
  display: block;
  line-height: $line-height-body;
}

.checklist__item {
  --link-color: #{$color-dark-green-100};
  --link-hover-color: #{color.mix($color-black, $color-dark-green-100, 20%)};

  display: flex;

  & + & {
    margin-top: 1rem;
  }
}

.checklist__icon {
  color: $color-dark-green-100;
  flex-shrink: 0;
  line-height: 0;
  margin-right: 1rem;
  margin-top: calc((1em * #{$line-height-body} - 1em) / 2);
}
