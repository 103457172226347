.footer {
  --link-color: #{$color-grey-20};
  --link-hover-color: #{$color-white};
  --link-underline-height: 1px;
  --footer-item-gap: calc(1.8rem * #{$line-height-body});

  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 4rem,
    m: 5rem,
    l: 7rem,
    xl: 8rem,
  ));

  background-color: $color-grey-100;
  color: $color-grey-30;
  position: relative;
}

.footer__inner {
  @include use-container();
  @include use-responsive-sizing(column-gap, $gaps);
  @include use-responsive-sizing(row-gap, map-multiply($gaps, 1.5));

  display: grid;
  grid-template-areas: 'navigation' 'logo' 'address';

  @include mq($from: s) {
    grid-template-areas: 'logo navigation' 'logo address';
    grid-template-columns: 1fr 1fr;
  }

  @include mq($from: l) {
    grid-template-areas: 'logo navigation address';
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.footer__logo {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  grid-area: logo;
  justify-content: flex-end;
  max-width: 22rem;
  opacity: 0.5;
}

.footer__navigation {
  grid-area: navigation;
}

.footer__navigation-items,
.footer__social-media-items {
  display: block;
}

.footer__navigation-item,
.footer__social-media-item {
  display: block;
  font-size: 1.8rem;

  & + & {
    margin-top: var(--footer-item-gap);
  }
}

.footer__navigation-link,
.footer__social-media-link {
  display: block;
}

.footer__social-media {
  border-top: 1px solid $color-grey-75;
  margin-top: var(--footer-item-gap);
  padding-top: var(--footer-item-gap);
}

.footer__social-media-legend {
  margin-bottom: var(--footer-item-gap);
}

.footer__address {
  --link-color: #{$color-grey-30};
  --link-hover-color: #{$color-grey-20};
  --link-font-weight: #{$font-weight-regular};

  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  gap: var(--footer-item-gap);
  grid-area: address;
  justify-content: flex-end;

  @include mq($from: l) {
    text-align: right;
  }
}
