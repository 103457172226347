.text-image {
  @include use-responsive-sizing(gap, $gaps);

  display: flex;
  flex-wrap: wrap;

  @include mq($from: s) {
    flex-wrap: nowrap;
  }
}

.text-image--right {
  @include mq($from: m) {
    flex-direction: row-reverse;
  }
}

.text-image__image {
  @include use-responsive-sizing(width, (
    s: column-width(s, 5),
    m: column-width(m, 5),
    l: column-width(l, 4),
    xl: column-width(xl, 4),
  ));

  flex-grow: 1;
  flex-shrink: 0;
  max-width: 40rem;
}

.text-image__text {
  @include use-responsive-sizing(font-size, (
    xs: 1.8rem,
    m: 1.9rem,
    l: 2rem,
  ));

  flex-grow: 1;
  line-height: $line-height-body;

  > * + * {
    margin-top: 1em;
  }

  > * + .headline {
    margin-top: 1.5em;
  }
}
