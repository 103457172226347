@use 'sass:color';

.details {
  --link-color: #{$color-brown-100};
  --link-hover-color: #{color.mix($color-black, $color-brown-100, 20%)};

  background-color: $color-brown-15;
  border: 1px solid $color-brown-20;
  font-size: 1.8rem;
  line-height: $line-height-body;
}

.details__heading {
  border-bottom: 1px solid $color-brown-20;
  font-size: 2rem;
  font-weight: $font-weight-bold;
  padding: 1rem 1.5rem;
}

.details__list {
  display: grid;
  grid-template-columns: min-content auto;
}

.details__label {
  font-weight: $font-weight-bold;
  text-align: right;
}

.details__label {
  min-width: 10rem;
}

.details__label,
.details__text {
  padding: 1rem 1.5rem;
}

.details__label:first-child,
.details__label:first-child + .details__text {
  padding-top: 2rem;
}

.details__label:nth-last-child(2),
.details__text:last-child {
  padding-bottom: 2rem;
}

.details__text {
  background-color: $color-brown-10;
  border-left: 1px solid $color-brown-20;
}
