.alternating-grid {
  @include use-responsive-sizing(--alternating-grid-gap, $gaps);

  column-gap: var(--alternating-grid-gap);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: calc(var(--alternating-grid-gap) * 2);

  @include mq($from: l) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.alternating-grid__teaser {
  display: grid;
  gap: var(--alternating-grid-gap);
  grid-template: 'image' 'content';
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem;
  position: relative;
  text-align: center;

  &:focus-within {
    @include use-outline();
  }

  @include mq($from: s) {
    grid-template: 'image content' auto / 1fr 2fr;
    max-width: none;
    text-align: left;

    &:nth-child(even) {
      grid-template: 'content image' auto / 2fr 1fr;
    }
  }

  @include mq($from: m) {
    grid-template: 'image content' auto / 1fr 3fr;

    &:nth-child(even) {
      grid-template: 'content image' auto / 3fr 1fr;
    }
  }

  @include mq($from: l) {
    grid-template: 'image' 'content' auto / 1fr;

    &:nth-child(even) {
      grid-template: 'content' 'image' auto / 1fr;
    }
  }
}

.alternating-grid__image {
  grid-area: image;
}

.alternating-grid__content {
  grid-area: content;
}

.alternating-grid__headline {
  --focus-outline-width: 0;
}

.alternating-grid__description {
  line-height: $line-height-body;
  margin-top: 1rem;
}
